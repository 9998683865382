




































import { Component, Vue, Watch } from 'vue-property-decorator';
import { dispatchSocialLogIn } from '@/store/main/actions';

@Component
export default class SocialLogin extends Vue {

  public sheet = true;
  public async created() {
    if (!this.$route.query.error) {
      this.sheet = false;
      const provider = this.$route.query.provider.toString();
      const code = this.$route.query.code.toString();
      await dispatchSocialLogIn(this.$store, { provider, code });
    }
  }
  @Watch('$route.query.error')
  public watchForError(error) {
    if (error) {
      this.sheet = true;
    }
  }
  public backToLogin() {
    this.$router.push('/login');
  }
}
